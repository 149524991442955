<template>
    <list-page
        page-title="Customers"
        page-icon="mdi-account-multiple"
        collection-name="customer"
        :items-per-page="20"
        add-dialog-title="Add Customer"
        is-filter
    />
</template>

<script>
import ListPage from '../../../components/pages/List';

export default {
    name: 'CustomerList',
    components: { ListPage },
};
</script>

<style scoped></style>
